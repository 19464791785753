const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const searchDish = (value, group_id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/find?value=${value}&group_id=${group_id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}