const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const getGroups = (jwt) => {
  try {
    return axios.post(getUri('api/dish/groups'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getPageGroups = (page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/dish/groups?page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getGroup = (id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/group/${id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const toogleGroup = (id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/group/${id}/toogle`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const upGroup = (id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/group/${id}/up`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const downGroup = (id, jwt) => {
  try {
    return axios.post(getUri(`api/dish/group/${id}/down`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}